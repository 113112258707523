.hero__wrap {
	position: relative;
}
.swiper {
	position: relative;
	width: 100%;
	height: 702px;
	border-radius: 30px;
}

.swiper-slide {
	position: relative;
	border-radius: 30px;
	background-color: var(--bg);
}

.swiper-slid__text {
	margin-top: 140px;
	margin-left: 60px;
}

.swiper-slid__title {
	font-weight: 700;
	// font-size: clamp(40px, 4.72727vw, 52px);
	font-size: clamp(2.5rem, -7.6053rem + 15.7895vw, 3.25rem);
	line-height: 128%;
	& span {
		color: var(--akcent);
	}
}

.swiper-slid__descr {
	margin-top: 60px;
	font-size: 14px;
	max-width: 340px;
}

.swiper-slid__img {
	position: absolute;
	z-index: -1;
	right: 0;
	bottom: 0;
	object-fit: contain;
	&_slid-1 {
		width: 421px;
		height: 694px;
	}
	&_slid-2 {
		width: 458px;
		height: 700px;
		right: -66px;
	}
	&_slid-3 {
		width: 577px;
		height: 545px;
		right: -54px;
		bottom: 0;
	}
	&_slid-4 {
		width: 322px;
		height: 646px;
		bottom: -28px;
		right: -2px;
	}
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
	top: 19px;
	height: 18px;
}

.swiper-pagination-bullet {
	width: 6px;
	height: 6px;
	background-color: var(--gray);
}
.swiper-pagination-bullet-active {
	background-color: var(--akcent);
}

.swiper-button-prev,
.swiper-button-next {
	width: 35px;
	height: 35px;
	top: calc(100% - 140px);
}
.swiper-button-prev {
	left: 60px;
}
.swiper-button-next {
	right: calc(100% - 137px);
}

.swiper-button-prev::after,
.swiper-button-next::after {
	font-size: 0;
}

.swiper-button-prev svg,
.swiper-button-next svg {
	fill: var(--gray);
	transition: fill 0.3s ease-in-out;
	&:hover {
		fill: var(--akcent);
	}
}
.swiper-button-next svg {
	rotate: 180deg;
}

// form// form// form// form// form// form// form// form// form// form// form// form// form// form// form

.hero__form {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	z-index: 5;
	left: 50%;
	transform: translateX(-50%);
	bottom: 22px;
	width: calc(100% - 88px);
	padding: 15px 31px;
	border-radius: 12px;
	background-color: var(--white);
}

.form__wrap {
	flex: 0 1 700px;
}

.hero__form-descr {
	max-width: 143px;
	font-size: 14px;
}

.form {
	display: flex;
	align-items: center;
	column-gap: 4px;
}
.form__label {
	position: relative;
	flex: 0 1 276px;
}
.input {
	width: 100%;
	padding: 12px 0 12px 12px;
	border: 1px solid var(--gray);
	border-radius: 8px;
	height: 50px;
}
.button {
	margin-left: 20px;
}
.just-validate-error-label {
	max-width: 220px;
	position: absolute;
	top: -18px;
	left: 2px;
	font-size: 12px;
}

@media (max-width: 1025px) {
	.swiper {
		height: 508px;
	}
	.swiper-slid__text {
		margin-top: 64px;
	}

	.swiper-slid__title {
		font-size: clamp(2.5rem, -1.0167rem + 6.6667vw, 3.25rem);
	}

	.swiper-slid__descr {
		margin-top: 30px;
	}

	.swiper-button-next,
	.swiper-button-prev {
		top: calc(100% - 160px);
	}

	.swiper-slid__img {
		&_slid-1 {
			width: 272px;
			height: 448px;
		}
		&_slid-2 {
			width: 264px;
			height: 400px;
			right: 0;
		}
		&_slid-3 {
			width: 400px;
			height: 390px;
			right: 0;
		}
		&_slid-4 {
			width: 200px;
			height: 400px;
		}
	}
	.form__label {
		flex: 1 1 201px;
	}
}

@media (max-width: 840px) {
	.swiper-slid__title {
		font-size: clamp(1.5rem, 0.9042rem + 2.4444vw, 2.1875rem);
	}
	.swiper-slid__text {
		margin-left: 22px;
	}
	.swiper-slid__img {
		&_slid-1 {
			width: 335px;
			height: 414px;
		}
		&_slid-2 {
			width: 239px;
			height: 370px;
		}
		&_slid-3 {
			width: 328px;
			height: 423px;
			right: -7px;
		}
		&_slid-4 {
			width: 223px;
			height: 436px;
		}
	}
	.hero__form {
		flex-wrap: wrap;
	}
	.hero__form-descr {
		max-width: 960px;
		margin-bottom: 12px;
	}
	.just-validate-error-label {
		top: -16px;
		font-size: 12px;
		left: 1px;
	}
	.button {
		margin-left: 10px;
	}
}

@media (max-width: 660px) {
	.swiper {
		height: 540px;
	}
	.swiper-slid__img {
		&_slid-1 {
			width: 243px;
			height: 298px;
		}
		&_slid-2 {
			width: 199px;
			height: 291px;
		}
		&_slid-3 {
			width: 300px;
			height: 284px;
		}
		&_slid-4 {
			width: 154px;
			height: 309px;
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		top: calc(100% - 32px);
	}
	.swiper-button-next {
		right: calc(100% - 97px);
	}
	.swiper-button-prev {
		left: 22px;
	}
	.swiper-slid__descr {
		padding-right: 25px;
	}

	.hero__form {
		position: relative;
		flex-direction: column;
		width: 100%;
		padding: 30px 22px;
		margin-top: 16px;
		bottom: 0;
		border-radius: 30px;
		background-color: var(--bg);
	}

	.form__wrap {
		width: 100%;
		flex: 0 0 auto;
	}
	.form {
		flex-direction: column;
		row-gap: 17px;
	}
	.hero__form .button {
		margin-top: 12px;
	}

	.hero__form-descr {
		align-self: flex-start;
		max-width: 143px;
		margin-bottom: 22px;
		font-size: 18px;
	}

	.form__label {
		flex: 0 0 auto;
		align-self: stretch;
	}

	.form-hero__btn {
		margin-top: 12px;
		align-self: stretch;
	}
	.button {
		margin-left: 0px;
	}
}

@media (max-width: 430px) {
	.swiper-slid__img_slid-4 {
		height: 259px;
	}
}

@media (max-width: 380px) {
	.swiper-slid__img_slid-2 {
		height: 258px;
	}
}

// services// services// services// services// services// services// services
.span__diz {
	position: absolute;
	top: 32px;
	right: 42px;
	font-size: 14px;
}

.border-title {
	display: inline-block;
	padding: 14px;
	border: solid 1px var(--white);
	border-radius: 8px;
}

.services {
	margin-top: 140px;
}

.services__represent-block {
	display: flex;
	justify-content: space-between;
}
.services__represent-title {
	font-size: clamp(1.25rem, -0.31rem + 6.4vw, 2.25rem);
}

.services__represent-descr {
	max-width: 209px;
	align-content: flex-end;
	padding-bottom: 5px;
}

.services__represent-img {
	position: relative;
	margin-top: 100px;
	border-radius: 30px;
	max-width: 1100px;
	padding-bottom: 83%;
	overflow: hidden;
	& img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.services__service-block {
	display: flex;
	column-gap: 16px;
	margin-top: 16px;
}

.services__service {
	position: relative;
	flex: 0 1 50%;
	padding: 30px;
	color: var(--white);
	background: var(--akcent);
	border-radius: 30px;
}

.services__service-title {
	border-color: var(--white);
}

.services__service-descr {
	margin-top: 60px;
	margin-bottom: 135px;
	max-width: 394px;
	font-size: 14px;
}

.services__price-block {
	flex: 0 1 50%;
	border-radius: 30px;
	background-color: var(--bg);
	position: relative;
	padding: 30px;
	border-radius: 30px;
}

.services__price-title {
	font-size: 20px;
	line-height: 128%;
	& span {
		color: var(--akcent);
	}
}

.services__price-nav {
	margin-top: 29px;
}
.services__price-list {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}

.services__price-item {
	display: contents;
	&:hover {
		border: 1px solid var(--akcent);
	}
}

.services__price-link {
	border: 1px solid var(--gray);
	border-radius: 8px;
	padding: 8px 12px;
	color: var(--gray);
	transition: all 0.3s ease-in-out;
	&:hover {
		color: var(--akcent);
		border-color: var(--akcent);
	}
}

.services__all-price {
	display: block;
	margin-top: 40px;
	margin-left: 11px;
	text-align: center;
}

.services__veneers-block {
	position: relative;
	margin-top: 16px;
	padding: 30px;
	border-radius: 30px;
	background: var(--akcent);
	background: var(--bg);
	font-size: 14px;
}

.services__veneers-tile {
	border-color: var(--akcent);
}

.services__veneers-descr {
	max-width: 497px;
	margin: 60px 0 89px;
}

a.services__veneers-link {
	color: var(--dark);
	&::after {
		background-image: url(../img/svgicons/arrow-right-black.svg);
	}
}

.services__veneers-img {
	position: absolute;
	right: 70px;
	bottom: 0;
	width: 362px;
	height: 316px;
}

@media (max-width: 1025px) {
	.services {
		margin-top: 100px;
	}

	.services__represent-img {
		margin-top: 40px;
		height: 0;
		padding-bottom: 98%;
	}

	.services__service-block {
		flex-direction: column;
		row-gap: 16px;
	}
	.services__service-descr {
		margin-bottom: 84px;
	}
	.services__all-price {
		margin-left: 0;
	}
	.services__veneers-descr {
		margin-bottom: 65px;
	}
	.services__veneers-img {
		width: 257px;
		height: 224px;
		right: 0;
	}
}

@media (max-width: 840px) {
	.services__represent-title {
		padding-left: 22px;
	}
	.services__represent-descr {
		display: none;
	}
	.services {
		margin-top: 100px;
	}
	.services__price-block {
		padding: 30px 22px;
	}
	.services__veneers-img {
		opacity: 0.3;
	}
	.services__service {
		padding: 30px 22px;
	}
	.services__veneers-block {
		padding: 30px 22px;
	}
}
@media (max-width: 660px) {
	.services__price-title {
		max-width: 220px;
	}
	.services__veneers-img {
		display: none;
	}
}

@media (max-width: 350px) {
	.span__diz {
		display: none;
	}
}
// // about// // about// // about// // about// // about// // about// // about

.about {
	margin: 140px 0 47px;
}

.about__wrap {
	position: relative;
}

.about__title {
	display: inline;
	margin-bottom: 114px;
}

.services__btn {
	position: absolute;
	right: 15px;
	top: 21px;
	max-width: 318px;
	width: 100%;
}

.about__content {
	margin-top: 114px;
	display: grid;
	grid-template-columns: minmax(250px, 449px) minmax(250px, 635px);
	grid-template-rows: 364px 115px 407px;
	gap: 0px 16px;
	grid-auto-flow: row;
	grid-template-areas:
		"about__img_1 about__descr_1"
		"about__img_1 about__img_2"
		"about__descr_2 about__img_2";
}

.about__img {
	position: relative;
	border-radius: 30px;
	overflow: hidden;
	& img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.about__img_1 {
	grid-area: about__img_1;
	& img {
		object-fit: cover;
	}
}

.about__img_2 {
	grid-area: about__img_2;
	& img {
		object-fit: cover;
	}
}

.about__descr_1 {
	padding-top: 31px;
	grid-area: about__descr_1;
	max-width: 570px;
}

.about__descr_2 {
	padding-bottom: 30px;
	grid-area: about__descr_2;
	align-self: end;
	max-width: 434px;
}

@media (max-width: 1025px) {
	.about {
		margin: 100px 0;
		padding-bottom: 100px;
	}
	.about__content {
		margin-top: 40px;
		grid-template-rows: 480px auto 480px auto;
		grid-template-columns: 1fr;
		row-gap: 16px;
	}

	.about__img_1 {
		grid-column: 1 / 3;
		grid-row: 1 / 2;
	}
	.about__descr_1 {
		max-width: 100%;
		grid-column: 1 / 3;
		grid-row: 2 / 3;
		padding-top: 0;
	}
	.about__img_2 {
		grid-column: 1 / 3;
		grid-row: 3 / 4;
		margin-top: 25px;
		& img {
			object-position: center -18px;
		}
	}

	.about__descr_2 {
		max-width: 100%;
		grid-column: 1 / 3;
		grid-row: 4 / 5;
		padding-bottom: 0;
	}
}

@media (max-width: 840px) {
	.about__title {
		display: block;
		margin-left: 0px;
		margin-bottom: 40px;
		padding-left: 22px;
	}
	.services__btn {
		max-width: calc(100% - 30px);
		left: 50%;
		height: 59px;
		top: calc(100% + 24px);
		transform: translateX(-50%);
	}
	.about__img_2 {
		& img {
			object-position: center -30px;
		}
	}
}

@media (max-width: 660px) {
	.about__img_2 {
		& img {
			object-position: center -30px;
		}
	}
}

@media (max-width: 410px) {
	.about__img_2 {
		& img {
			object-position: center center;
		}
	}
}

@media (max-width: 660px) {
	.about__title {
		font-size: clamp(1.25rem, -0.31rem + 6.4vw, 2.25rem);
	}
}

// license
.license {
	padding: 110px 0 38px;
	background-color: var(--akcent);
}
.license__wrap {
	position: relative;
}
.license__title {
	max-width: 300px;
	margin-bottom: 101px;
	font-weight: 700;
	font-size: 36px;
	line-height: 128%;
	color: var(--white);
}
.license-slider {
	position: relative;
	width: calc(100% + 30px);
	overflow: hidden;
}

.license-slider__wrap {
	display: flex;
	width: 100%;
}

.license-slider__slid {
	flex-shrink: 0;
	position: relative;
	height: 494px;
	border-radius: 30px;
	overflow: hidden;
}

.container__buttons {
	margin: 0;
	padding-right: 30px;
}

.license-slide__buttons {
	position: absolute;
	width: max-content;
	display: inline;
	top: 45px;
	right: 0;
}
.license-slide__button-prev,
.license-slide__button-next {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	border: var(--white) 1px solid;
	background-image: url(../img/svgicons/arrow-white.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}
.license-slide__button-next {
	rotate: 180deg;
}

.license-slider__pag {
	margin-top: 16px;
	text-align: center;
}

.license-slider__pag .swiper-pagination-bullet {
	height: 6px;
	width: 6px;
	background-color: var(--white);
	opacity: 1;
}

.license-slider__pag .swiper-pagination-bullet-active {
	background-color: var(--dark);
	opacity: 1;
}

.license-slider__slid-content {
	cursor: pointer;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 6;
	left: 0;
	top: 0px;
	width: 100%;
	height: 100%;
	padding: 44px 36px 39px 36px;
	border-radius: 30px;
	background-color: #fff;
	transition: all ease-in-out 0.3s;
	&::after {
		content: "";
		position: absolute;
		z-index: 7;
		right: 34px;
		top: 46px;
		width: 25px;
		height: 25px;
		background-image: url(../img/svgicons/arrow-right-akcent.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		transition: rotate ease-in-out 0.3s;
	}
	&:hover {
		top: 36px;
	}
}
.license-slider__slid:hover {
	.license-slider__slid-content::after {
		rotate: 50deg;
	}
}

.license-slider__slid-title {
	font-weight: 700;
	font-size: 20px;
	color: var(--dark);
}
.license-slider__slid-descr {
	padding-top: auto;
}

.license-slider__img {
	position: absolute;
	z-index: 4;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

@media (max-width: 1025px) {
	.license {
		padding: 36px 0 22px;
	}
	.license__title {
		margin-bottom: 22px;
		font-size: 20px;
	}
	.license-slider {
		width: 142.5%;
	}

	.license-slide__buttons {
		top: -9px;
	}
	.license-slider__pag {
		padding-right: 30%;
	}
}

@media (max-width: 840px) {
	.license-slider {
		width: calc(100% + 100px);
	}
	.swiper-pagination-bullets.swiper-pagination-horizontal {
		width: calc(100% - 100px);
	}
	.license-slider__pag {
		padding-right: 0;
	}
}
@media (max-width: 660px) {
	.license-slider {
		width: calc(100% - 15px);
	}
	.swiper-pagination-bullets.swiper-pagination-horizontal {
		width: 100%;
	}
}

//map //map//map//map//map//map//map//map//map//map//map//map
.map {
	margin: 140px 0 16px;
}

.map__title {
	margin-bottom: 151px;
}

.map__map {
	position: relative;
	& iframe {
		border-radius: 30px;
		overflow: hidden;
	}
}

.map__form {
	position: absolute;
	z-index: 5;
	top: -51px;
	right: 48px;
	max-width: 417px;
	padding: 36px 47px;
	background-color: var(--bg);
	border-radius: 30px;
}

.map__form-title {
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: 400;
}

.map__form-descr {
	max-width: 300px;
	margin-bottom: 30px;
	font-size: 14px;
	color: var(--gray);
}

.form-map {
	flex-direction: column;
	row-gap: 12px;
	height: 178px;
	& label {
		align-self: stretch;
	}
	& input {
		width: 100%;
	}
}

.form-map__button {
	margin-top: 16px;
	align-self: stretch;
	margin-left: 0;
}

.form-map .just-validate-error-label {
	top: -14px;
}

@media (max-width: 1025px) {
	.map {
		margin: 100px 0 16px;
	}
	.map__title {
		margin-bottom: 105px;
	}
}

@media (max-width: 840px) {
	.map__title {
		padding-left: 22px;
	}
}
@media (max-width: 660px) {
	.map__title {
		font-size: clamp(1.25rem, -0.31rem + 6.4vw, 2.25rem);
		margin-bottom: 48px;
	}
	.map__map {
		display: flex;
		flex-direction: column-reverse;
		align-self: center;
	}
	.map__form {
		position: relative;
		max-width: 700px;
		top: 0;
		right: 0;
		padding: 26px 22px 40px 22px;
		margin-bottom: 16px;
	}
}

///////////////////////// .modal

.modal-overlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	z-index: 10;

	backdrop-filter: blur(29px);
	background: rgba(255, 255, 255, 0.8);
}

.modal {
	display: none;
	position: relative;
	height: 100vh;
	aspect-ratio: 2/3;

	align-items: center;
	justify-content: center;
	overflow: hidden;
	padding: 0 65px;
	// background-color: rgba(255, 255, 255, 0.863);
}
.modal_img {
	padding: 0;
}

.modal-overlay--visible {
	opacity: 1;
	visibility: visible;
	transition: all 0.3s ease-in-out;
}

.modal--visible {
	position: relative;
	// display: block;
	display: flex;
}

.modal-swiper {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.modal-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	border-radius: 30px;
}

.modal-slide {
	position: relative;
	flex-shrink: 0;
}

.modal-slid__img {
	position: absolute;
	z-index: 99;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	aspect-ratio: 2/3;
	object-fit: contain;
}
.modal-button-prev,
.modal-button-next {
	position: absolute;
	top: 50%;
	width: 35px;
	height: 35px;
	transform: translateY(-50%);
	cursor: pointer;
}

.modal-button-prev {
	left: 0;
}

.modal-button-next {
	right: 0;
	top: calc(50% - 35px);
	rotate: 180deg;
}

.modal-button-prev svg,
.modal-button-next svg {
	fill: var(--dark);
	z-index: 99;
	width: 100%;
	height: 100%;
	object-fit: contain;
	transition: opacity 0.3s ease-in-out;
}

.modal-button-next.swiper-button-disabled svg,
.modal-button-prev.swiper-button-disabled svg {
	opacity: 0.35;
}

.modal-overlay::after {
	content: "";
	position: absolute;
	top: 35px;
	right: 32px;
	width: 35px;
	height: 35px;
	background-image: url(../img/svgicons/close.svg);
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
}
