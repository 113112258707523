.page__body {
	margin: 0;
	padding: 0;
	min-height: 2000px;
	overflow-x: hidden;
}

.header {
	border-bottom: 1px solid var(--second-text);
	padding: 20px 0;
	color: var(--akcent);
}

.header__container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav__list {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
}

.nav__item:not(:last-child) {
	margin-right: 30px;
}

.burger {
	position: relative;
	width: 35px;
	height: 35px;
	display: none;
	border: none;
	cursor: pointer;
	border-radius: 50%;
	background-image: url(../img/svgicons/menu.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

.stop-scroll {
	overflow: hidden;
}
.nav_contacts {
	display: none;
}

@media (max-width: 844px) {
	.header__phone {
		display: none;
	}

	.burger {
		display: block;
	}

	.burger_close {
		position: absolute;
		width: 35px;
		height: 35px;
		top: 21px;
		border-radius: 50%;
		background-image: url(../img/svgicons/menu-close.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}

	.nav {
		position: fixed;
		right: -5px;
		top: 0px;
		z-index: 99;
		padding-top: 60px;
		padding-bottom: 40px;
		padding-left: 30px;
		max-width: 300px;
		border-radius: 30px 0 0 30px;
		background-color: var(--white);
		transform: translateX(100%);
		transition: transform 0.6s ease-in-out;
		overflow-y: auto;
	}

	.nav--visible {
		transform: none;
	}

	.nav__list {
		flex-direction: column;
		align-items: flex-end;
		row-gap: 20px;
		margin-right: 27px;
	}

	.nav__item {
		width: 100%;
	}

	.nav__item:not(:last-child) {
		margin-right: 0;
	}

	.nav__link {
		border: none;
		padding: 0;
		font-size: 19px;
	}
	.nav_contacts {
		display: block;
		margin-top: 54px;
		& a {
			display: block;
			margin-top: 14px;
			color: var(--dark);
			text-align: right;
			font-size: 20px;
		}
	}
}
