.pryce {
	margin-top: 50px;
}

.pryce__title {
	margin: 70px 0 30px 0;
	font-size: clamp(1.25rem, -1.6806rem + 5.5556vw, 1.875rem);
}

.pryce__title_lite {
	margin: 100px 0 12px 0;
}

.pryce__type {
	margin: 42px 0 12px 0px;
	padding: 11px 0 11px 13px;
	border-radius: 4px;
	background-color: var(--akcent);
	font-weight: 600;
	font-size: 18px;
	color: var(--white);
}

.pryce__type-block {
	display: flex;
	flex-direction: column;
	row-gap: 6px;
}
.pryce__type-block_sub.pryce__type-item,
.pryce__type-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 7px 16px 3px 16px;
	color: var(--dark, #45494f);
	font-size: 15px;
	font-weight: 400;
	border-radius: 4px;
}

.pryce__type-item_limpid {
	padding: 0;
}

.pryce__type-item:nth-child(even) {
	background-color: var(--bg);
}

.pryce__type-item_limpid:nth-child(odd),
.pryce__type-item_limpid:nth-child(even) {
	background-color: transparent;
}
.pryce__type-name {
	flex: 0 1 450px;
	padding-right: 20px;
}
.pryce__type-value {
	padding-left: 30px;
	flex: 0 0 100px;
	text-align: right;
}

.pryce__type-block_sub {
	flex: 1 1 100%;
	margin-top: 26px;
	margin-bottom: 0;
	color: var(--dark, #45494f);
	font-size: 16px;
	font-weight: 600;
}

.pryce__pages {
	margin-top: 70px;
}
.pryce__pages-list {
	display: flex;
	justify-content: center;
	column-gap: 16px;
}

.pryce__pages-item {
	width: 35px;
	height: 35px;
	border-radius: 100%;
	border: solid transparent 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: border 0.3s ease-in-out;
	&:hover {
		border: solid var(--akcent) 1px;
	}
}

.pryce__pages-item_active {
	border: solid var(--akcent) 1px;
}

.pryce__pages-link {
	width: 35px;
	height: 35px;
	text-align: center;
	line-height: 35px;
	color: var(--dark);
}

@media (max-width: 840px) {
	.pryce__type-item {
		font-size: 14px;
		padding:  6px 16px 7px 16px;
	}

	
}

@media (max-width: 660px) {
	.pryce__title {
		margin: 15px 0 8px 0;
	}
	.pryce__type-block_sub {
		font-size: clamp(0.875rem, 0.5357rem + 1.4286vw, 1.125rem);
		margin-top: 10px;
	}
	.pryce__type {
		font-size: clamp(1rem, 0.6607rem + 1.4286vw, 1.25rem);
		margin-top: 30px;
	}
}

@media (max-width: 420px) {
	.pryce__type-item {
		font-size: clamp(0.75rem, 0.35rem + 2vw, 0.875rem);
	}
	.pryce__type-name{
		padding-right: 0;
	}
}
