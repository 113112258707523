:root {
	--container-width: 1130px;
	--container-padding: 15px;

	--font-main: "Evolventa", sans-serif;

	--page-bg: #fff;
	--black: #000;

	--dark: #45494f;
	--akcent: #11d081;
	--gray: #b8b8b8;
	--white: #fff;
	--bg: #f8f8f8;
	--red: #f22b2b;
}
h1,
h2,
h3,
.map__form-title,
.pryce__title {
	font-family: var(--font-main);
	font-weight: 700;
	font-size: 36px;
	line-height: 128%;
	color: var(--dark);
	& span {
		color: var(--akcent);
	}
}

@media (max-width: 840px) {
	h2,
	h3 {
		margin-left: 0px;
	}
}

input {
	font-size: 12px;
}
input:focus {
	border-color: var(--akcent);
}

input::placeholder {
	opacity: 0.5;
}

.services__btn {
	display: block;
	border-radius: 12px;
	padding: 20px 0 20px;
	background: var(--akcent);
	color: var(--white);
	text-align: center;
}

.button {
	padding: 13px 30px;
	background-color: var(--akcent);
	border: solid 1px var(--akcent);
	color: var(--white);
	border-radius: 8px;
	font-size: 16px;
	transition: all 0.3s ease-in-out;
	&:hover {
		background-color: transparent;
		color: var(--akcent);
	}
}

@media (max-width: 660px) {
	.button {
		width: 100%;
	}
}

.link-too {
	display: inline-flex;
	column-gap: 6px;
	align-items: center;
	font-size: 16px;
	& span {
		position: relative;
		color: var(--white);
		transition: all 0.3s ease-in-out;
		padding-bottom: 2px;
		&::after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			height: 1px;
			width: 100%;
			background-color: var(--white);
			transition: background-color 0.3s ease-in-out;
		}
	}
	& svg {
		width: 16px;
		height: 16px;
		object-fit: contain;
		fill: var(--white);
		transform-origin: center;
		transition: all 0.3s ease-in-out;
	}
}

.link-too_dark {
	position: relative;
	& span {
		color: var(--dark);
		&::after {
			background-color: var(--dark);
		}
	}
	& svg {
		fill: var(--dark);
	}
}
.link-too_dark:hover{
	& span {
		color: var(--akcent);
		&::after {
			background-color: var(--akcent);
		}
	}
	& svg {
		fill: var(--akcent);
	}
}


.link-too:hover {
	& svg {
		rotate: 45deg;
	}
}


//<ПОДКЛЮЧЕНИЕ СТИЛЕЙ>======================================================
// @media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
// @media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
// @media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
// @media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }
