.header {
	padding: 20px 0 12px;
	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
	}
}

.header__logo {
	position: relative;
	width: 140px;
	height: 38px;
	overflow: hidden;
	& img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.nav__list {
	display: flex;
	justify-content: space-between;
	column-gap: 6px;
}

.nav__link {
	padding: 10px 16px;
	border: solid 1px var(--gray);
	border-radius: 8px;
	color: var(--dark);
	transition: all ease-in-out 0.3s;
	&:hover {
		border-color: var(--akcent);
		color: var(--akcent);
	}
}
.header__phone{
	display: flex;
	flex-direction: column;
	row-gap: 7px;
}

.header__phone a {
	position: relative;
	padding-left: 21px;
	color: var(--dark);
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 14px;
		height: 14px;
		background-image: url(../img/svgicons/phone.svg);
		background-repeat: no-repeat;
		background-size: contain;
	}
}

@media (max-width: 840px) {
	.header__wrap{
		padding-left: 22px;
		padding-left: 22px;
	}
}