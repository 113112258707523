@charset "UTF-8";

/* Reset and base styles  */

* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

:root {
  --container-width: 1130px;
  --container-padding: 15px;
  --font-main: "Evolventa", sans-serif;
  --page-bg: #fff;
  --black: #000;
  --dark: #45494f;
  --akcent: #11d081;
  --gray: #b8b8b8;
  --white: #fff;
  --bg: #f8f8f8;
  --red: #f22b2b;
}

h1,
h2,
h3,
.map__form-title,
.pryce__title {
  font-family: var(--font-main);
  font-weight: 700;
  font-size: 36px;
  line-height: 128%;
  color: var(--dark);
}

h1 span,
h2 span,
h3 span,
.map__form-title span,
.pryce__title span {
  color: var(--akcent);
}

input {
  font-size: 12px;
}

input:focus {
  border-color: var(--akcent);
}

input::placeholder {
  opacity: 0.5;
}

.services__btn {
  display: block;
  border-radius: 12px;
  padding: 20px 0 20px;
  background: var(--akcent);
  color: var(--white);
  text-align: center;
}

.button {
  padding: 13px 30px;
  background-color: var(--akcent);
  border: solid 1px var(--akcent);
  color: var(--white);
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  background-color: transparent;
  color: var(--akcent);
}

.link-too {
  display: inline-flex;
  column-gap: 6px;
  align-items: center;
  font-size: 16px;
}

.link-too span {
  position: relative;
  color: var(--white);
  transition: all 0.3s ease-in-out;
  padding-bottom: 2px;
}

.link-too span::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: var(--white);
  transition: background-color 0.3s ease-in-out;
}

.link-too svg {
  width: 16px;
  height: 16px;
  object-fit: contain;
  fill: var(--white);
  transform-origin: center;
  transition: all 0.3s ease-in-out;
}

.link-too_dark {
  position: relative;
}

.link-too_dark span {
  color: var(--dark);
}

.link-too_dark span::after {
  background-color: var(--dark);
}

.link-too_dark svg {
  fill: var(--dark);
}

.link-too_dark:hover span {
  color: var(--akcent);
}

.link-too_dark:hover span::after {
  background-color: var(--akcent);
}

.link-too_dark:hover svg {
  fill: var(--akcent);
}

.link-too:hover svg {
  rotate: 45deg;
}

@font-face {
  font-family: "Evolventa";
  font-display: swap;
  src: url("../fonts/Evolventa-Bold.woff") format("woff"), url("../fonts/Evolventa-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Evolventa";
  font-display: swap;
  src: url("../fonts/Evolventa-Regular.woff") format("woff"), url("../fonts/Evolventa-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  background-color: var(--white);
  overflow-x: hidden;
}

body {
  background-color: var(--white);
  color: var(--dark);
  font-family: var(--font-main);
  font-size: 16px;
  font-weight: 400;
}

img {
  display: block;
}

code {
  background-color: #e9f1f6;
  padding: 0.2rem;
  border-radius: 4px;
}

pre.code {
  overflow-x: auto;
  background-color: #e9f1f6;
}

html,
body,
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
}

/* Контейнеры */

.container {
  margin: 0 auto;
  padding: 0 var(--container-padding);
  max-width: var(--container-width);
  width: 100%;
}

.container-full {
  padding: 0 var(--container-padding);
  max-width: 100%;
}

.container-left-50 {
  padding: 0 var(--container-padding);
  max-width: 50%;
}

.container-right-50 {
  padding: 0 var(--container-padding);
  max-width: 50%;
  margin-left: auto;
}

.container-right {
  padding-left: calc((100% - var(--container-width)) / 2 + var(--container-padding));
}

.container-left {
  padding-right: calc((100% - var(--container-width)) / 2 + var(--container-padding));
}

.container-half-left {
  padding-right: calc((100% - var(--container-width)) / 2 + var(--container-width) / 2);
  padding-left: calc((100% - var(--container-width)) / 2 + var(--container-padding));
}

.container-half-right {
  padding-left: calc((100% - var(--container-width)) / 2 + var(--container-width) / 2);
  padding-right: calc((100% - var(--container-width)) / 2 + var(--container-padding));
}

.header {
  padding: 20px 0 12px;
}

.header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.header__logo {
  position: relative;
  width: 140px;
  height: 38px;
  overflow: hidden;
}

.header__logo img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nav__list {
  display: flex;
  justify-content: space-between;
  column-gap: 6px;
}

.nav__link {
  padding: 10px 16px;
  border: solid 1px var(--gray);
  border-radius: 8px;
  color: var(--dark);
  transition: all ease-in-out 0.3s;
}

.nav__link:hover {
  border-color: var(--akcent);
  color: var(--akcent);
}

.header__phone {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.header__phone a {
  position: relative;
  padding-left: 21px;
  color: var(--dark);
}

.header__phone a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
  background-image: url(../img/svgicons/phone.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.page__body {
  margin: 0;
  padding: 0;
  min-height: 2000px;
  overflow-x: hidden;
}

.header {
  border-bottom: 1px solid var(--second-text);
  padding: 20px 0;
  color: var(--akcent);
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.nav__item:not(:last-child) {
  margin-right: 30px;
}

.burger {
  position: relative;
  width: 35px;
  height: 35px;
  display: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background-image: url(../img/svgicons/menu.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.stop-scroll {
  overflow: hidden;
}

.nav_contacts {
  display: none;
}

.footer__wrap {
  display: flex;
  justify-content: space-between;
  padding: 31px 45px;
  border-radius: 30px;
  background-color: var(--akcent);
  color: var(--white);
}

.footer__logo {
  position: relative;
  width: 140px;
  height: 38px;
  margin-bottom: 30px;
}

.footer__logo img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer__metro-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 30px;
}

.footer__metro-item {
  position: relative;
  padding-left: 22px;
}

.footer__metro-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 12px;
  background-image: url(../img/svgicons/metro.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.footer__contacts {
  font-size: 40px;
  align-self: flex-end;
}

.footer__contacts a {
  color: var(--white);
}

.footer__contacts-item {
  display: block;
  text-align: right;
}

.hero__wrap {
  position: relative;
}

.swiper {
  position: relative;
  width: 100%;
  height: 702px;
  border-radius: 30px;
}

.swiper-slide {
  position: relative;
  border-radius: 30px;
  background-color: var(--bg);
}

.swiper-slid__text {
  margin-top: 140px;
  margin-left: 60px;
}

.swiper-slid__title {
  font-weight: 700;
  font-size: clamp(2.5rem, -7.6053rem + 15.7895vw, 3.25rem);
  line-height: 128%;
}

.swiper-slid__title span {
  color: var(--akcent);
}

.swiper-slid__descr {
  margin-top: 60px;
  font-size: 14px;
  max-width: 340px;
}

.swiper-slid__img {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  object-fit: contain;
}

.swiper-slid__img_slid-1 {
  width: 421px;
  height: 694px;
}

.swiper-slid__img_slid-2 {
  width: 458px;
  height: 700px;
  right: -66px;
}

.swiper-slid__img_slid-3 {
  width: 577px;
  height: 545px;
  right: -54px;
  bottom: 0;
}

.swiper-slid__img_slid-4 {
  width: 322px;
  height: 646px;
  bottom: -28px;
  right: -2px;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 19px;
  height: 18px;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background-color: var(--gray);
}

.swiper-pagination-bullet-active {
  background-color: var(--akcent);
}

.swiper-button-prev,
.swiper-button-next {
  width: 35px;
  height: 35px;
  top: calc(100% - 140px);
}

.swiper-button-prev {
  left: 60px;
}

.swiper-button-next {
  right: calc(100% - 137px);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 0;
}

.swiper-button-prev svg,
.swiper-button-next svg {
  fill: var(--gray);
  transition: fill 0.3s ease-in-out;
}

.swiper-button-prev svg:hover,
.swiper-button-next svg:hover {
  fill: var(--akcent);
}

.swiper-button-next svg {
  rotate: 180deg;
}

.hero__form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  bottom: 22px;
  width: calc(100% - 88px);
  padding: 15px 31px;
  border-radius: 12px;
  background-color: var(--white);
}

.form__wrap {
  flex: 0 1 700px;
}

.hero__form-descr {
  max-width: 143px;
  font-size: 14px;
}

.form {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.form__label {
  position: relative;
  flex: 0 1 276px;
}

.input {
  width: 100%;
  padding: 12px 0 12px 12px;
  border: 1px solid var(--gray);
  border-radius: 8px;
  height: 50px;
}

.button {
  margin-left: 20px;
}

.just-validate-error-label {
  max-width: 220px;
  position: absolute;
  top: -18px;
  left: 2px;
  font-size: 12px;
}

.span__diz {
  position: absolute;
  top: 32px;
  right: 42px;
  font-size: 14px;
}

.border-title {
  display: inline-block;
  padding: 14px;
  border: solid 1px var(--white);
  border-radius: 8px;
}

.services {
  margin-top: 140px;
}

.services__represent-block {
  display: flex;
  justify-content: space-between;
}

.services__represent-title {
  font-size: clamp(1.25rem, -0.31rem + 6.4vw, 2.25rem);
}

.services__represent-descr {
  max-width: 209px;
  align-content: flex-end;
  padding-bottom: 5px;
}

.services__represent-img {
  position: relative;
  margin-top: 100px;
  border-radius: 30px;
  max-width: 1100px;
  padding-bottom: 83%;
  overflow: hidden;
}

.services__represent-img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services__service-block {
  display: flex;
  column-gap: 16px;
  margin-top: 16px;
}

.services__service {
  position: relative;
  flex: 0 1 50%;
  padding: 30px;
  color: var(--white);
  background: var(--akcent);
  border-radius: 30px;
}

.services__service-title {
  border-color: var(--white);
}

.services__service-descr {
  margin-top: 60px;
  margin-bottom: 135px;
  max-width: 394px;
  font-size: 14px;
}

.services__price-block {
  flex: 0 1 50%;
  border-radius: 30px;
  background-color: var(--bg);
  position: relative;
  padding: 30px;
  border-radius: 30px;
}

.services__price-title {
  font-size: 20px;
  line-height: 128%;
}

.services__price-title span {
  color: var(--akcent);
}

.services__price-nav {
  margin-top: 29px;
}

.services__price-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.services__price-item {
  display: contents;
}

.services__price-item:hover {
  border: 1px solid var(--akcent);
}

.services__price-link {
  border: 1px solid var(--gray);
  border-radius: 8px;
  padding: 8px 12px;
  color: var(--gray);
  transition: all 0.3s ease-in-out;
}

.services__price-link:hover {
  color: var(--akcent);
  border-color: var(--akcent);
}

.services__all-price {
  display: block;
  margin-top: 40px;
  margin-left: 11px;
  text-align: center;
}

.services__veneers-block {
  position: relative;
  margin-top: 16px;
  padding: 30px;
  border-radius: 30px;
  background: var(--akcent);
  background: var(--bg);
  font-size: 14px;
}

.services__veneers-tile {
  border-color: var(--akcent);
}

.services__veneers-descr {
  max-width: 497px;
  margin: 60px 0 89px;
}

a.services__veneers-link {
  color: var(--dark);
}

a.services__veneers-link::after {
  background-image: url(../img/svgicons/arrow-right-black.svg);
}

.services__veneers-img {
  position: absolute;
  right: 70px;
  bottom: 0;
  width: 362px;
  height: 316px;
}

.about {
  margin: 140px 0 47px;
}

.about__wrap {
  position: relative;
}

.about__title {
  display: inline;
  margin-bottom: 114px;
}

.services__btn {
  position: absolute;
  right: 15px;
  top: 21px;
  max-width: 318px;
  width: 100%;
}

.about__content {
  margin-top: 114px;
  display: grid;
  grid-template-columns: minmax(250px, 449px) minmax(250px, 635px);
  grid-template-rows: 364px 115px 407px;
  gap: 0px 16px;
  grid-auto-flow: row;
  grid-template-areas: "about__img_1 about__descr_1" "about__img_1 about__img_2" "about__descr_2 about__img_2";
}

.about__img {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}

.about__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.about__img_1 {
  grid-area: about__img_1;
}

.about__img_1 img {
  object-fit: cover;
}

.about__img_2 {
  grid-area: about__img_2;
}

.about__img_2 img {
  object-fit: cover;
}

.about__descr_1 {
  padding-top: 31px;
  grid-area: about__descr_1;
  max-width: 570px;
}

.about__descr_2 {
  padding-bottom: 30px;
  grid-area: about__descr_2;
  align-self: end;
  max-width: 434px;
}

.license {
  padding: 110px 0 38px;
  background-color: var(--akcent);
}

.license__wrap {
  position: relative;
}

.license__title {
  max-width: 300px;
  margin-bottom: 101px;
  font-weight: 700;
  font-size: 36px;
  line-height: 128%;
  color: var(--white);
}

.license-slider {
  position: relative;
  width: calc(100% + 30px);
  overflow: hidden;
}

.license-slider__wrap {
  display: flex;
  width: 100%;
}

.license-slider__slid {
  flex-shrink: 0;
  position: relative;
  height: 494px;
  border-radius: 30px;
  overflow: hidden;
}

.container__buttons {
  margin: 0;
  padding-right: 30px;
}

.license-slide__buttons {
  position: absolute;
  width: max-content;
  display: inline;
  top: 45px;
  right: 0;
}

.license-slide__button-prev,
.license-slide__button-next {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: var(--white) 1px solid;
  background-image: url(../img/svgicons/arrow-white.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.license-slide__button-next {
  rotate: 180deg;
}

.license-slider__pag {
  margin-top: 16px;
  text-align: center;
}

.license-slider__pag .swiper-pagination-bullet {
  height: 6px;
  width: 6px;
  background-color: var(--white);
  opacity: 1;
}

.license-slider__pag .swiper-pagination-bullet-active {
  background-color: var(--dark);
  opacity: 1;
}

.license-slider__slid-content {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 6;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 44px 36px 39px 36px;
  border-radius: 30px;
  background-color: #fff;
  transition: all ease-in-out 0.3s;
}

.license-slider__slid-content::after {
  content: "";
  position: absolute;
  z-index: 7;
  right: 34px;
  top: 46px;
  width: 25px;
  height: 25px;
  background-image: url(../img/svgicons/arrow-right-akcent.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transition: rotate ease-in-out 0.3s;
}

.license-slider__slid-content:hover {
  top: 36px;
}

.license-slider__slid:hover .license-slider__slid-content::after {
  rotate: 50deg;
}

.license-slider__slid-title {
  font-weight: 700;
  font-size: 20px;
  color: var(--dark);
}

.license-slider__slid-descr {
  padding-top: auto;
}

.license-slider__img {
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.map {
  margin: 140px 0 16px;
}

.map__title {
  margin-bottom: 151px;
}

.map__map {
  position: relative;
}

.map__map iframe {
  border-radius: 30px;
  overflow: hidden;
}

.map__form {
  position: absolute;
  z-index: 5;
  top: -51px;
  right: 48px;
  max-width: 417px;
  padding: 36px 47px;
  background-color: var(--bg);
  border-radius: 30px;
}

.map__form-title {
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 400;
}

.map__form-descr {
  max-width: 300px;
  margin-bottom: 30px;
  font-size: 14px;
  color: var(--gray);
}

.form-map {
  flex-direction: column;
  row-gap: 12px;
  height: 178px;
}

.form-map label {
  align-self: stretch;
}

.form-map input {
  width: 100%;
}

.form-map__button {
  margin-top: 16px;
  align-self: stretch;
  margin-left: 0;
}

.form-map .just-validate-error-label {
  top: -14px;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  backdrop-filter: blur(29px);
  background: rgba(255, 255, 255, 0.8);
}

.modal {
  display: none;
  position: relative;
  height: 100vh;
  aspect-ratio: 2/3;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 65px;
}

.modal_img {
  padding: 0;
}

.modal-overlay--visible {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

.modal--visible {
  position: relative;
  display: flex;
}

.modal-swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.modal-slide {
  position: relative;
  flex-shrink: 0;
}

.modal-slid__img {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 2/3;
  object-fit: contain;
}

.modal-button-prev,
.modal-button-next {
  position: absolute;
  top: 50%;
  width: 35px;
  height: 35px;
  transform: translateY(-50%);
  cursor: pointer;
}

.modal-button-prev {
  left: 0;
}

.modal-button-next {
  right: 0;
  top: calc(50% - 35px);
  rotate: 180deg;
}

.modal-button-prev svg,
.modal-button-next svg {
  fill: var(--dark);
  z-index: 99;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.3s ease-in-out;
}

.modal-button-next.swiper-button-disabled svg,
.modal-button-prev.swiper-button-disabled svg {
  opacity: 0.35;
}

.modal-overlay::after {
  content: "";
  position: absolute;
  top: 35px;
  right: 32px;
  width: 35px;
  height: 35px;
  background-image: url(../img/svgicons/close.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.pryce {
  margin-top: 50px;
}

.pryce__title {
  margin: 70px 0 30px 0;
  font-size: clamp(1.25rem, -1.6806rem + 5.5556vw, 1.875rem);
}

.pryce__title_lite {
  margin: 100px 0 12px 0;
}

.pryce__type {
  margin: 42px 0 12px 0px;
  padding: 11px 0 11px 13px;
  border-radius: 4px;
  background-color: var(--akcent);
  font-weight: 600;
  font-size: 18px;
  color: var(--white);
}

.pryce__type-block {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.pryce__type-block_sub.pryce__type-item,
.pryce__type-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 16px 3px 16px;
  color: var(--dark, #45494f);
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
}

.pryce__type-item_limpid {
  padding: 0;
}

.pryce__type-item:nth-child(even) {
  background-color: var(--bg);
}

.pryce__type-item_limpid:nth-child(odd),
.pryce__type-item_limpid:nth-child(even) {
  background-color: transparent;
}

.pryce__type-name {
  flex: 0 1 450px;
  padding-right: 20px;
}

.pryce__type-value {
  padding-left: 30px;
  flex: 0 0 100px;
  text-align: right;
}

.pryce__type-block_sub {
  flex: 1 1 100%;
  margin-top: 26px;
  margin-bottom: 0;
  color: var(--dark, #45494f);
  font-size: 16px;
  font-weight: 600;
}

.pryce__pages {
  margin-top: 70px;
}

.pryce__pages-list {
  display: flex;
  justify-content: center;
  column-gap: 16px;
}

.pryce__pages-item {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: solid transparent 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 0.3s ease-in-out;
}

.pryce__pages-item:hover {
  border: solid var(--akcent) 1px;
}

.pryce__pages-item_active {
  border: solid var(--akcent) 1px;
}

.pryce__pages-link {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  color: var(--dark);
}

@media (max-width: 1220px) {
  .container-right {
    padding-left: var(--container-padding);
  }

  .container-left {
    padding-right: var(--container-padding);
  }

  .container-half-left {
    padding-left: var(--container-padding);
  }

  .container-half-right {
    padding-right: var(--container-padding);
  }
}

@media (max-width: 1025px) {
  .swiper {
    height: 508px;
  }

  .swiper-slid__text {
    margin-top: 64px;
  }

  .swiper-slid__title {
    font-size: clamp(2.5rem, -1.0167rem + 6.6667vw, 3.25rem);
  }

  .swiper-slid__descr {
    margin-top: 30px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: calc(100% - 160px);
  }

  .swiper-slid__img_slid-1 {
    width: 272px;
    height: 448px;
  }

  .swiper-slid__img_slid-2 {
    width: 264px;
    height: 400px;
    right: 0;
  }

  .swiper-slid__img_slid-3 {
    width: 400px;
    height: 390px;
    right: 0;
  }

  .swiper-slid__img_slid-4 {
    width: 200px;
    height: 400px;
  }

  .form__label {
    flex: 1 1 201px;
  }

  .services {
    margin-top: 100px;
  }

  .services__represent-img {
    margin-top: 40px;
    height: 0;
    padding-bottom: 98%;
  }

  .services__service-block {
    flex-direction: column;
    row-gap: 16px;
  }

  .services__service-descr {
    margin-bottom: 84px;
  }

  .services__all-price {
    margin-left: 0;
  }

  .services__veneers-descr {
    margin-bottom: 65px;
  }

  .services__veneers-img {
    width: 257px;
    height: 224px;
    right: 0;
  }

  .about {
    margin: 100px 0;
    padding-bottom: 100px;
  }

  .about__content {
    margin-top: 40px;
    grid-template-rows: 480px auto 480px auto;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }

  .about__img_1 {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .about__descr_1 {
    max-width: 100%;
    grid-column: 1/3;
    grid-row: 2/3;
    padding-top: 0;
  }

  .about__img_2 {
    grid-column: 1/3;
    grid-row: 3/4;
    margin-top: 25px;
  }

  .about__img_2 img {
    object-position: center -18px;
  }

  .about__descr_2 {
    max-width: 100%;
    grid-column: 1/3;
    grid-row: 4/5;
    padding-bottom: 0;
  }

  .license {
    padding: 36px 0 22px;
  }

  .license__title {
    margin-bottom: 22px;
    font-size: 20px;
  }

  .license-slider {
    width: 142.5%;
  }

  .license-slide__buttons {
    top: -9px;
  }

  .license-slider__pag {
    padding-right: 30%;
  }

  .map {
    margin: 100px 0 16px;
  }

  .map__title {
    margin-bottom: 105px;
  }
}

@media (max-width: 844px) {
  .header__phone {
    display: none;
  }

  .burger {
    display: block;
  }

  .burger_close {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 21px;
    border-radius: 50%;
    background-image: url(../img/svgicons/menu-close.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .nav {
    position: fixed;
    right: -5px;
    top: 0px;
    z-index: 99;
    padding-top: 60px;
    padding-bottom: 40px;
    padding-left: 30px;
    max-width: 300px;
    border-radius: 30px 0 0 30px;
    background-color: var(--white);
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out;
    overflow-y: auto;
  }

  .nav--visible {
    transform: none;
  }

  .nav__list {
    flex-direction: column;
    align-items: flex-end;
    row-gap: 20px;
    margin-right: 27px;
  }

  .nav__item {
    width: 100%;
  }

  .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .nav__link {
    border: none;
    padding: 0;
    font-size: 19px;
  }

  .nav_contacts {
    display: block;
    margin-top: 54px;
  }

  .nav_contacts a {
    display: block;
    margin-top: 14px;
    color: var(--dark);
    text-align: right;
    font-size: 20px;
  }
}

@media (max-width: 840px) {
  h2,
  h3 {
    margin-left: 0px;
  }

  .header__wrap {
    padding-left: 22px;
    padding-left: 22px;
  }

  .footer__contacts {
    font-size: clamp(1.5rem, -2.1667rem + 8.8889vw, 2.5rem);
  }

  .footer__wrap {
    display: flex;
    justify-content: space-between;
    padding: 31px 22px;
  }

  .swiper-slid__title {
    font-size: clamp(1.5rem, 0.9042rem + 2.4444vw, 2.1875rem);
  }

  .swiper-slid__text {
    margin-left: 22px;
  }

  .swiper-slid__img_slid-1 {
    width: 335px;
    height: 414px;
  }

  .swiper-slid__img_slid-2 {
    width: 239px;
    height: 370px;
  }

  .swiper-slid__img_slid-3 {
    width: 328px;
    height: 423px;
    right: -7px;
  }

  .swiper-slid__img_slid-4 {
    width: 223px;
    height: 436px;
  }

  .hero__form {
    flex-wrap: wrap;
  }

  .hero__form-descr {
    max-width: 960px;
    margin-bottom: 12px;
  }

  .just-validate-error-label {
    top: -16px;
    font-size: 12px;
    left: 1px;
  }

  .button {
    margin-left: 10px;
  }

  .services__represent-title {
    padding-left: 22px;
  }

  .services__represent-descr {
    display: none;
  }

  .services {
    margin-top: 100px;
  }

  .services__price-block {
    padding: 30px 22px;
  }

  .services__veneers-img {
    opacity: 0.3;
  }

  .services__service {
    padding: 30px 22px;
  }

  .services__veneers-block {
    padding: 30px 22px;
  }

  .about__title {
    display: block;
    margin-left: 0px;
    margin-bottom: 40px;
    padding-left: 22px;
  }

  .services__btn {
    max-width: calc(100% - 30px);
    left: 50%;
    height: 59px;
    top: calc(100% + 24px);
    transform: translateX(-50%);
  }

  .about__img_2 img {
    object-position: center -30px;
  }

  .license-slider {
    width: calc(100% + 100px);
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    width: calc(100% - 100px);
  }

  .license-slider__pag {
    padding-right: 0;
  }

  .map__title {
    padding-left: 22px;
  }

  .pryce__type-item {
    font-size: 14px;
    padding: 6px 16px 7px 16px;
  }
}

@media (max-width: 820px) {
  .container-left-50 {
    max-width: 100%;
  }

  .container-right-50 {
    max-width: 100%;
  }

  .container-half-left {
    padding: 0 var(--container-padding);
  }

  .container-half-right {
    padding: 0 var(--container-padding);
  }
}

@media (max-width: 660px) {
  .button {
    width: 100%;
  }

  .footer__wrap {
    flex-direction: column;
    row-gap: 84px;
  }

  .swiper {
    height: 540px;
  }

  .swiper-slid__img_slid-1 {
    width: 243px;
    height: 298px;
  }

  .swiper-slid__img_slid-2 {
    width: 199px;
    height: 291px;
  }

  .swiper-slid__img_slid-3 {
    width: 300px;
    height: 284px;
  }

  .swiper-slid__img_slid-4 {
    width: 154px;
    height: 309px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: calc(100% - 32px);
  }

  .swiper-button-next {
    right: calc(100% - 97px);
  }

  .swiper-button-prev {
    left: 22px;
  }

  .swiper-slid__descr {
    padding-right: 25px;
  }

  .hero__form {
    position: relative;
    flex-direction: column;
    width: 100%;
    padding: 30px 22px;
    margin-top: 16px;
    bottom: 0;
    border-radius: 30px;
    background-color: var(--bg);
  }

  .form__wrap {
    width: 100%;
    flex: 0 0 auto;
  }

  .form {
    flex-direction: column;
    row-gap: 17px;
  }

  .hero__form .button {
    margin-top: 12px;
  }

  .hero__form-descr {
    align-self: flex-start;
    max-width: 143px;
    margin-bottom: 22px;
    font-size: 18px;
  }

  .form__label {
    flex: 0 0 auto;
    align-self: stretch;
  }

  .form-hero__btn {
    margin-top: 12px;
    align-self: stretch;
  }

  .button {
    margin-left: 0px;
  }

  .services__price-title {
    max-width: 220px;
  }

  .services__veneers-img {
    display: none;
  }

  .about__img_2 img {
    object-position: center -30px;
  }

  .about__title {
    font-size: clamp(1.25rem, -0.31rem + 6.4vw, 2.25rem);
  }

  .license-slider {
    width: calc(100% - 15px);
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    width: 100%;
  }

  .map__title {
    font-size: clamp(1.25rem, -0.31rem + 6.4vw, 2.25rem);
    margin-bottom: 48px;
  }

  .map__map {
    display: flex;
    flex-direction: column-reverse;
    align-self: center;
  }

  .map__form {
    position: relative;
    max-width: 700px;
    top: 0;
    right: 0;
    padding: 26px 22px 40px 22px;
    margin-bottom: 16px;
  }

  .pryce__title {
    margin: 15px 0 8px 0;
  }

  .pryce__type-block_sub {
    font-size: clamp(0.875rem, 0.5357rem + 1.4286vw, 1.125rem);
    margin-top: 10px;
  }

  .pryce__type {
    font-size: clamp(1rem, 0.6607rem + 1.4286vw, 1.25rem);
    margin-top: 30px;
  }
}

@media (max-width: 430px) {
  .swiper-slid__img_slid-4 {
    height: 259px;
  }
}

@media (max-width: 420px) {
  .pryce__type-item {
    font-size: clamp(0.75rem, 0.35rem + 2vw, 0.875rem);
  }

  .pryce__type-name {
    padding-right: 0;
  }
}

@media (max-width: 410px) {
  .about__img_2 img {
    object-position: center center;
  }
}

@media (max-width: 380px) {
  .swiper-slid__img_slid-2 {
    height: 258px;
  }
}

@media (max-width: 350px) {
  .span__diz {
    display: none;
  }
}