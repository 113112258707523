.footer__wrap {
	display: flex;
	justify-content: space-between;
	padding: 31px 45px;
	border-radius: 30px;
	background-color: var(--akcent);
	color: var(--white);
}

.footer__logo {
	position: relative;
	width: 140px;
	height: 38px;
	margin-bottom: 30px;
	& img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
.footer__metro-list {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	margin-bottom: 30px;
}
.footer__metro-item {
	position: relative;
	padding-left: 22px;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 16px;
		height: 12px;
		background-image: url(../img/svgicons/metro.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}
}

.footer__contacts {
	font-size: 40px;
	align-self: flex-end;
	& a {
		color: var(--white);
	}
}
.footer__contacts-item {
	display: block;
	text-align: right;
}

@media (max-width: 840px) {
	.footer__contacts {
		font-size: clamp(1.5rem, -2.1667rem + 8.8889vw, 2.5rem);
	}
	.footer__wrap {
		display: flex;
		justify-content: space-between;
		padding: 31px 22px;
	}
}
@media (max-width: 660px) {
	.footer__wrap {
		flex-direction: column;
		row-gap: 84px;
	}
}
