html {
	scroll-behavior: smooth;
	background-color: var(--white);
	overflow-x: hidden;
}

body {
	background-color: var(--white);
	color: var(--dark);
	font-family: var(--font-main);
	font-size: 16px;
	font-weight: 400;
	// overflow-x: hidden;
}

img {
	display: block;
}

code {
	background-color: #e9f1f6;
	padding: 0.2rem;
	border-radius: 4px;
}

pre.code {
	overflow-x: auto;
	background-color: #e9f1f6;
}

html,
body,
.wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.footer {
	margin-top: auto;
}
